import { put } from 'redux-saga/effects';
import { gameMachineConnectedAction, gameMachineDisconnectedAction } from 'data/reducers';
import { Notifications, GameMachineConnectionResponse } from 'types';
import * as Sentry from '@sentry/react';

export function* gameMachineConnection({
  type: notificationType,
  data,
}: GameMachineConnectionResponse) {
  try {
    if (notificationType === Notifications.GAME_MACHINE_CONNECTION) {
      const { status } = data;
      if (status === 'CONNECTED') {
        yield put(gameMachineConnectedAction());
      }
      if (status === 'DISCONNECTED') {
        yield put(gameMachineDisconnectedAction());
      }
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
