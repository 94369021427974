import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { NotificationManager } from 'react-notifications';
import * as Sentry from '@sentry/react';

import {
  loginRequstAction,
  loginSuccessAction,
  loginFailureAction,
  loginToSocketRequestAction,
  loginToSocketSuccessAction,
  loginToSocketFailureAction,
  initAppSuccessAction,
  logOutAction,
  putLogInToStoreAction,
  connectToWebSocketAction,
} from 'data/reducers';
import { AuthService } from 'services';

import { ACTIONS } from '../constants';

function* refreshTokenSaga() {
  const refreshToken = localStorage.getItem('refresh_token');

  try {
    const {
      data: { access, refresh },
    } = yield call(() => AuthService.refreshToken(refreshToken!));

    yield call(() => localStorage.setItem('access_token', access));
    yield call(() => localStorage.setItem('refresh_token', refresh));

    yield put(loginSuccessAction());
    yield put(initAppSuccessAction());
  } catch (error) {
    yield put(logOutAction());
    yield put(initAppSuccessAction());
    Sentry.captureException({ error });
  }
}

function* logInSaga({ payload: { email, password } }: ActionType<typeof loginRequstAction>) {
  try {
    const {
      data: { access, refresh },
    } = yield call(() => AuthService.logIn(email, password));

    yield call(() => localStorage.setItem('access_token', access));
    yield call(() => localStorage.setItem('refresh_token', refresh));
    yield put(loginSuccessAction());
  } catch (error) {
    yield put(loginFailureAction(error));
    Sentry.captureException({ error });
  }
}

function* logoutSaga() {
  try {
    yield call(() => localStorage.removeItem('access_token'));
    yield call(() => localStorage.removeItem('refresh_token'));
    yield put(putLogInToStoreAction(false));
  } catch (error) {
    Sentry.captureException({ error });
    NotificationManager.error('Error');
  }
}

function* logInToSocketSaga({
  payload: { tableId, shiftManagerId },
}: ActionType<typeof loginToSocketRequestAction>) {
  try {
    const {
      data: { token },
    } = yield call(() => AuthService.logInToSocket(tableId, shiftManagerId));

    yield call(() => sessionStorage.setItem('access_to_ws_token', token));
    yield put(loginToSocketSuccessAction());
    if (token) {
      yield put(connectToWebSocketAction({ tableId, token }));
    }
  } catch (error) {
    Sentry.captureException({ error });
    yield put(loginToSocketFailureAction(error));
  }
}

export function* authorizationSaga() {
  yield all([
    takeEvery(ACTIONS.LOGIN_REQUEST, logInSaga),
    takeEvery(ACTIONS.LOGIN_TO_SOCKET_REQUEST, logInToSocketSaga),
    takeEvery(ACTIONS.LOGOUT, logoutSaga),
    takeLatest(ACTIONS.REFRESH_TOKEN_REQUEST, refreshTokenSaga),
  ]);
}
