import axios from 'axios';
import * as Sentry from '@sentry/react';

import { store } from 'core';
import { logOutAction } from 'data/reducers';

import { BaseService } from './BaseService';

const qs = require('querystring');

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (!error.response) return Promise.reject(error);

    if (error?.response?.data?.message) {
      const {
        status,
        config,
        data: { message: errorMessage },
      } = error.response;

      if (status === 403 && errorMessage.includes('Forbidden')) {
        return AuthService.refreshTokens()
          .then((token) => {
            config.headers.Authorization = `Bearer ${token}`;
            return axios.request(config);
          })
          .catch((err) => {
            if (err.response.status === 401 && err.response.data.message.includes('Unauthorized')) {
              store.dispatch(logOutAction());
            }
            Sentry.captureException({ err });
            return err;
          });
      }
    }

    Sentry.captureException({
      message: error.message,
      name: error.name,
      code: error.code,
      status: error.status,
      error,
    });
    return Promise.reject(error);
  },
);

class AuthAPIService extends BaseService {
  public async refreshToken(refreshToken: string) {
    const data = {
      refresh: refreshToken,
    };

    return this.post('jwt/refresh/', qs.stringify(data), {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  public async refreshTokens() {
    const refreshToken = localStorage.getItem('refresh_token');

    const body = {
      refresh: refreshToken,
    };

    const {
      data: { access, refresh },
    } = await this.post('jwt/refresh/', qs.stringify(body), {
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);

    return access;
  }

  public async logIn(email: string, password: string) {
    const data = {
      email,
      password,
    };

    return this.post('jwt/create/', qs.stringify(data), {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  public async logInToSocket(tableId: string, shiftManagerId: string) {
    const data = {
      tableId,
      shiftManagerId,
    };

    return this.post('socket/login/', data, {
      'Content-Type': 'application/json',
    });
  }
}

export const AuthService = new AuthAPIService();
