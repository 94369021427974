import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  // roundFinishedSuccessAction,
  changeGameStatusAction,
  changeGameStateAction,
  setIsBettingTime,
  setIsCancelRound,
  setIsStartRound,
  setIsScanTime,
  setIsRoundEnd,
  clearCardsToStore,
  setWinnerToStore,
  setCardsStatusToStore,
} from 'data/reducers';
import { RoundFinishedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* roundFinished(data: RoundFinishedResponse) {
  try {
    if (data.type === Notifications.ROUND_FINISHED) {
      yield put(changeGameStateAction({ tableState: TableStates.RoundFinished }));
      yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
      yield put(setCardsStatusToStore(null));
      // yield put(roundFinishedSuccessAction());
      // for tests
      yield put(clearCardsToStore());
      yield put(setWinnerToStore(null));
      yield put(setIsStartRound(false));
      yield put(setIsBettingTime(false));
      yield put(setIsCancelRound(false));
      yield put(setIsScanTime(false));
      yield put(setIsRoundEnd(false));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
