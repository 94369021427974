import * as Sentry from '@sentry/react';
import { pull } from 'lodash';
import { io, Socket } from 'socket.io-client';

import {
  INotificationResponse,
  ClientSideNotifications,
  Notifications,
  IDataResponse,
  PossibleResponse,
  ErrorNotifications,
} from 'types';
import {
  defaultListeners,
  clientSideConnectionListeners,
  errorListeners,
} from 'constants/webSocket';

class WebSocketService {
  ws: Socket | null = null;

  connectInterval: number | null = null;

  tableId: string | null = null;

  token: string | null = null;

  pingInterval: number | null = null;

  timeout = 250;

  listeners: {
    [key in Notifications]: Array<(notification: PossibleResponse) => void>;
  } = defaultListeners as {
    [key in Notifications]: Array<(notification: PossibleResponse) => void>;
  };

  socketConnectionClientListeners: {
    [key in ClientSideNotifications]: Array<(notification: string) => void>;
  } = clientSideConnectionListeners as {
    [key in ClientSideNotifications]: Array<(notification: string) => void>;
  };

  errorListeners: {
    [key in ErrorNotifications]: Array<(notification: string) => void>;
  } = errorListeners as {
    [key in ErrorNotifications]: Array<(notification: string) => void>;
  };

  manuallyClosed = false;

  connect = (tableId: string, token: string) => {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL || 'wss://dealer-websocket.com/dealer', {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
        tableid: tableId,
      },
    });
    this.tableId = tableId;
    this.token = token;

    socket.on('connect', () => {
      console.log('Websocket connected');

      this.ws = socket;
      this.manuallyClosed = false;

      if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }
      this.pingInterval = setInterval(() => {
        this.sendMessage({
          type: 'PING',
        });
      }, 30000) as any;

      this.timeout = 250;
      if (this.connectInterval) {
        clearTimeout(this.connectInterval);
      }
    });

    socket.on('disconnect', () => {
      console.log('Websocket closed');

      this.ws?.close();

      if (!this.manuallyClosed) {
        this.timeout += this.timeout;
        this.connectInterval = setTimeout(this.retry, Math.min(10000, this.timeout)) as any;
      }
      this.listeners = defaultListeners as {
        [key in Notifications]: Array<(notification: PossibleResponse) => void>;
      };
    });

    socket.on('error', (err) => {
      console.log('Websocket error', err);
      Sentry.captureException({ err });
      socket.close();
    });

    socket.on('connect_error', (err) => {
      console.log('Websocket connect_error', err);
    });

    Object.keys(this.listeners).forEach((listenerKey) =>
      socket.on(listenerKey, (event) => {
        this.listeners[listenerKey as Notifications].forEach((listener) => listener(event));
      }),
    );

    Object.keys(this.socketConnectionClientListeners).forEach((listenerKey) =>
      socket.on(listenerKey, () => {
        this.socketConnectionClientListeners[listenerKey as ClientSideNotifications].forEach(
          (listener) => listener(listenerKey),
        );
      }),
    );

    Object.keys(this.errorListeners).forEach((listenerKey) =>
      socket.on(listenerKey, (error) => {
        this.errorListeners[listenerKey as ErrorNotifications].forEach((listener) =>
          listener(error.message),
        );
      }),
    );
  };

  onMessage(listener: (notification: PossibleResponse) => void, type: Notifications) {
    this.listeners[type].push(listener);
    return () => {
      pull(this.listeners[type], listener);
    };
  }

  onClientMessage(listener: (notification: string) => void, type: ClientSideNotifications) {
    this.socketConnectionClientListeners[type].push(listener);
    return () => {
      pull(this.socketConnectionClientListeners[type], listener);
    };
  }

  onErrorMessage(listener: (notification: string) => void, type: ErrorNotifications) {
    this.errorListeners[type].push(listener);
    return () => {
      pull(this.errorListeners[type], listener);
    };
  }

  sendMessage(message: { type: string; data?: object }) {
    console.log('Websocket send message', message);
    const { ws } = this;

    return new Promise((resolve) => {
      if (ws) {
        ws.emit(message.type, message, (response: INotificationResponse<IDataResponse>) =>
          resolve(response),
        );
      }
    });
  }

  retry = () => {
    console.log('Websocket retry connect');
    const token = sessionStorage.getItem('access_to_ws_token');
    const { ws, tableId } = this;
    if (!ws || ws.connected === false) {
      if (token && tableId) {
        this.connect(tableId, token);
      }
    }
  };

  isConnected() {
    return this.ws && this.ws.connected === true;
  }

  disconnect() {
    if (this.ws) {
      this.manuallyClosed = true;
      this.ws.close();
    }
  }
}

export const webSocketService = new WebSocketService();
