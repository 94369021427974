import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';
import { gameCardsStatus } from 'constants/common';
import { setCardsStatusToStore, setIsScanTime } from 'data/reducers';

export function* waitingNextCard({ type: notificationType }: any) {
  try {
    if (notificationType === Notifications.WAITING_NEXT_CARD) {
      yield put(setCardsStatusToStore(gameCardsStatus.WaitingNextCard));
      yield put(setIsScanTime(true));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
