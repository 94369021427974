import { call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';

export function* startDrawSaga() {
  try {
    yield call(() => webSocketService.sendMessage({ type: Notifications.START_DRAW }));
  } catch (error) {
    Sentry.captureException({ error });
  }
}
