import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import { tableCloseAction, tableCloseSuccessAction } from 'data/reducers';
import { responseType } from 'constants/common';

export function* closeTableSaga({
  payload: { shiftManagerId, closingStatus },
}: ActionType<typeof tableCloseAction>) {
  try {
    const { type } = yield call(() =>
      webSocketService.sendMessage({
        type: Notifications.CLOSE_TABLE,
        data: { shiftManagerId, closing_status: closingStatus },
      }),
    );

    if (type === responseType.Ok) {
      yield put(tableCloseSuccessAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
