import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  saveGameIdToStore,
  startRoundSuccessAction,
  changeGameStateAction,
  removeToastAction,
} from 'data/reducers';
import { Notifications, RoundStartedResponse, TableStates } from 'types';

export function* roundStarted({ data: result, type: responseType }: RoundStartedResponse) {
  try {
    if (responseType === Notifications.ROUND_STARTED) {
      const { round } = result;
      const { gameId } = round;
      yield put(saveGameIdToStore({ gameId }));
      yield put(changeGameStateAction({ tableState: TableStates.RoundStarted }));
      yield put(startRoundSuccessAction());
      yield put(removeToastAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
