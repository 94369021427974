import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { refreshTokenRequstAction } from 'data/reducers';
import { Notifications, SocketPongResponse } from 'types';

export function* socketPongSaga({ data: result, type: responseType }: SocketPongResponse) {
  try {
    if (responseType === Notifications.PONG) {
      const { token } = result;
      if (token) {
        sessionStorage.setItem('access_to_ws_token', token);
      }
      yield put(refreshTokenRequstAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
