import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { tableClosedSuccessAction } from 'data/reducers';
import { Notifications, TableClosedResponse } from 'types';

export function* tableClosed({ type: notificationType }: TableClosedResponse) {
  try {
    if (notificationType === Notifications.TABLE_CLOSED) {
      yield put(tableClosedSuccessAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
