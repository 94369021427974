import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';
import { changeDealerAction, changeDealerFailureAction } from 'data/reducers';

import { responseType } from 'constants/common';

export function* changeDealerSaga({
  payload: { dealerId },
}: ActionType<typeof changeDealerAction>) {
  try {
    const { type, data } = yield call(() =>
      webSocketService.sendMessage({
        type: Notifications.CHANGE_DEALER,
        data: { dealerId },
      }),
    );

    if (type === responseType.Error) {
      yield put(changeDealerFailureAction(data));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
