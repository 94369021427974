import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { tableCloseNotificationAction, addToastAction } from 'data/reducers';
import { TableCloseVerifyResponse, Notifications, ToastTypes } from 'types';
import { alerts } from 'constants/common';

export function* tableCloseNotification({ type: notificationType }: TableCloseVerifyResponse) {
  try {
    if (notificationType === Notifications.NOTIFICATION) {
      yield put(tableCloseNotificationAction());
      yield put(addToastAction({ type: ToastTypes.WARNING, value: alerts.TABLE_CLOSED }));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
