import { TablesStatus } from 'constants/tables';

export enum TableStates {
  TableLoading = 'TABLE_LOADING',
  TableOpened = 'TABLE_OPENED',
  RoundStarted = 'ROUND_STARTED',
  BettingTimeStarted = 'BETTING_TIME_STARTED',
  BettingTimeFinished = 'BETTING_TIME_FINISHED',
  DrawStarted = 'DRAW_STARTED',
  DrawFinished = 'DRAW_FINISHED',
  ResultConfirmed = 'RESULT_CONFIRMED',
  RoundFinished = 'ROUND_FINISHED',
  RoundCanceled = 'ROUND_CANCELED',
}

export enum ResultBall {
  RED = 'RED',
  BLUE = 'BLUE',
  GOLD = 'GOLD',
  UNDEFINED = 'UNDEFINED',
}

export enum ToastTypes {
  SUCCESS = 'Success',
  ERROR = 'Error',
  WARNING = 'Warning',
  DEFAULT = 'Default',
}

export type ToastValueType = {
  text: string;
  displayTime?: number;
};

export type DrawResultValue = '0' | '1' | '2';

export interface IDrawResult {
  id: string;
  value: DrawResultValue;
}

export type ToastItemType = {
  type?: ToastTypes;
  value: ToastValueType;
};

interface ITimeLeft {
  bettingTimeLeft?: number;
  drawTimeLeft?: number;
  finishBettingTime?: Date;
}

export interface IRound {
  timeValue?: number;
  isFinished: boolean;
  drawResults: string[];
  winner?: string;
  timerLeft?: ITimeLeft;
}

export interface IDealer {
  avatar: string;
  name: string;
  nickname: string;
  tableId: string;
}

export interface ITableCommon {
  id: string;
  table_id: string;
  auto_round: boolean;
  auto_draw: boolean;
  auto_confirm_result: boolean;
  joker_high_low: boolean;
  betting_time: number;
  status: TablesStatus;
  stream_low: string | null;
  game_type: string;
  game_id: string;
  burn_card: boolean;
  additional: boolean;
  burnCardRequired: boolean;
  shuffleRequired: boolean;
}

export interface ITable extends ITableCommon {
  gameId?: string;
  dealer: IDealer;
  tableState: TableStates;
  statusText: string;
  round: IRound;
  closed?: {
    shiftManagerId?: string;
    verified?: boolean;
    isInProgress?: boolean;
    isClosed?: boolean;
    notification?: boolean;
  };
  changeResult?: {
    shiftManagerId?: string;
    verified?: boolean;
    isInProgress?: boolean;
    isChanged?: boolean;
  };
  shuffle?: {
    shiftManagerId?: string;
    verified?: boolean;
    isInProgress?: boolean;
    isClosed?: boolean;
    notification?: boolean;
  };
  toastItem: ToastItemType | null;
}
