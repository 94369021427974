import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  bettingTimeFinishedAction,
  changeGameStatusAction,
  changeGameStateAction,
  // bettingTimeStartedAction,
  // setIsScanTime,
} from 'data/reducers';
import { BettingTimeFinishedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* bettingTimeFinished({ type: notificationType }: BettingTimeFinishedResponse) {
  try {
    if (notificationType === Notifications.BETTING_TIME_FINISHED) {
      yield put(changeGameStatusAction({ status: gameStatus.NoMoreBets }));
      yield put(changeGameStateAction({ tableState: TableStates.BettingTimeFinished }));
      // yield put(bettingTimeStartedAction({ bettingTime: 0, additional: false }));
      yield put(bettingTimeFinishedAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
    console.log(error);
  }
}
