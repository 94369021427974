import { put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  changeGameStatusAction,
  setCardsStatusToStore,
  setIsJokerCardRound,
  setIsScanTime,
  setIsStartRound,
  setJokerCardToStore,
  shuffleVerifyAction,
} from 'data/reducers';
import { AddedCardResponse, Notifications } from 'types';
import { gameStatus } from 'constants/common';
import { getGameData } from 'core/selectors';

export function* changeJokerCard({ type: notificationType, data: result }: AddedCardResponse) {
  try {
    if (notificationType === Notifications.JOKER_CHANGED) {
      const { joker_high_low } = yield select(getGameData);

      if (!joker_high_low) {
        return;
      }

      const { card: joker } = result;
      yield put(setIsJokerCardRound(Boolean(joker)));
      yield put(setJokerCardToStore(joker));
      yield put(setIsScanTime(false));
      yield put(setCardsStatusToStore(null));

      if (joker) {
        yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
        yield put(setIsJokerCardRound(true));
        yield put(setIsStartRound(false));
        return;
      }

      if (!joker) {
        yield put(shuffleVerifyAction({ shiftManagerId: '' }));
        yield put(changeGameStatusAction({ status: gameStatus.JokerCard }));
        yield put(setCardsStatusToStore(''));
        yield put(setIsJokerCardRound(false));
        yield put(setIsStartRound(true));
      }
    }
  } catch (error) {
    Sentry.captureException({ error });
    console.log(error);
  }
}
