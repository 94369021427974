import { call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { ActionType } from 'typesafe-actions';

import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';
import { cancelRoundAction, cancelRoundFailureAction } from 'data/reducers';

import { responseType } from 'constants/common';

export function* cancelRoundSaga({
  payload: { shiftManagerId },
}: ActionType<typeof cancelRoundAction>) {
  try {
    const { type, data } = yield call(() =>
      webSocketService.sendMessage({ type: Notifications.CANCEL_ROUND, data: { shiftManagerId } }),
    );

    if (type === responseType.Error) {
      yield put(cancelRoundFailureAction(data));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
