import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { changeResultSuccessAction } from 'data/reducers';
import { Notifications, ResultChangedResponse } from 'types';

export function* resultChanged({ type: notificationType }: ResultChangedResponse) {
  try {
    if (notificationType === Notifications.RESULT_CHANGED) {
      yield put(changeResultSuccessAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
