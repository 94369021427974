import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as Sentry from '@sentry/react';

import { webSocketService } from 'services/WebSocketService';
import { Notifications } from 'types';
import { responseType } from 'constants/common';
import { openTableAction, openTableFailureAction } from '../../reducers';

export function* openTableSaga({ payload: { id } }: ActionType<typeof openTableAction>) {
  try {
    const { type, data } = yield call(() =>
      webSocketService.sendMessage({
        type: Notifications.OPEN_TABLE,
        data: { shiftManagerId: id },
      }),
    );

    if (type === responseType.Error) {
      yield put(openTableFailureAction(data));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
