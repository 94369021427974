import { call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import {
  tableCloseVerifyAction,
  shuffleVerifyFailureAction,
  shuffleVerifySuccessAction,
} from 'data/reducers';
import { ActionType } from 'typesafe-actions';

import { responseType } from 'constants/common';

export function* shuffleSaga({
  payload: { shiftManagerId },
}: ActionType<typeof tableCloseVerifyAction>) {
  try {
    const { type, data } = yield call(() =>
      webSocketService.sendMessage({
        type: Notifications.SHUFFLE,
        data: { shiftManagerId },
      }),
    );
    if (type === responseType.Error) {
      yield put(shuffleVerifyFailureAction(data));
    }

    if (type === responseType.Ok) {
      yield put(shuffleVerifySuccessAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
