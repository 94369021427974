import { changeDealerSuccessAction } from 'data/reducers';
import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { DealerChangedResponse, Notifications } from 'types';

export function* dealerChanged({ data: result, type: notificationType }: DealerChangedResponse) {
  try {
    if (notificationType === Notifications.DEALER_CHANGED) {
      yield put(changeDealerSuccessAction({ dealer: result.dealer }));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
