import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { store } from 'core';
import './index.css';
import { AuthService, TableService } from 'services';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

AuthService.setCredentials({ URL: process.env.REACT_APP_API_URL || 'https://dealer-api.com/' });
TableService.setCredentials({ URL: process.env.REACT_APP_API_URL || 'https://dealer-api.com/' });
AuthService.prefix = 'auth/';
console.log(
  'process.env.NODE_ENV_SENTRY',
  process.env.NODE_ENV_SENTRY,
  process.env.REACT_APP_API_URL,
);

Sentry.init({
  dsn: 'https://4ee2a92153d4150ed033f477233fe0b8@o4506795707072512.ingest.sentry.io/4506795801772032',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV_SENTRY,
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
