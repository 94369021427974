import { call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { ActionType } from 'typesafe-actions';

import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';

import {
  addNewCardEvent,
  setCardsStatusToStore,
  setHasDoubleCardToStore,
  setHasUndefinedCardToStore,
} from 'data/reducers';

export function* addNewCardSaga({ payload: { barcode } }: ActionType<typeof addNewCardEvent>) {
  try {
    yield put(setCardsStatusToStore(null));
    yield put(setHasUndefinedCardToStore(false));
    yield put(setHasDoubleCardToStore(false));

    const { type, data } = yield call(() =>
      webSocketService.sendMessage({
        type: Notifications.ADD_NEW_CARD,
        data: { barcode: barcode.trim() },
      }),
    );

    if (type === 'ERROR' && data?.reason === 'NOT_REGISTERED') {
      yield put(setHasUndefinedCardToStore(true));
    }

    if (type === 'ERROR' && data?.reason === 'ALREADY_PLAYED') {
      yield put(setHasDoubleCardToStore(true));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
