import { call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import {
  changeResultVerifyAction,
  changeResultVerifySuccessAction,
  changeResultVerifyFailureAction,
} from 'data/reducers';
import { ActionType } from 'typesafe-actions';

import { responseType } from 'constants/common';

export function* verifyChangeResultSaga({
  payload: { shiftManagerId },
}: ActionType<typeof changeResultVerifyAction>) {
  try {
    const { type, data } = yield call(() =>
      webSocketService.sendMessage({
        type: Notifications.VERIFY_SHIFT_MANAGER,
        data: { shiftManagerId },
      }),
    );

    if (type === responseType.Error) {
      yield put(changeResultVerifyFailureAction(data));
    }

    if (type === responseType.Ok) {
      yield put(changeResultVerifySuccessAction());
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
